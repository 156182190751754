import styled from "styled-components"

export const Style = styled.div`

    margin-top: 105px;
    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .title{
        color: #FCE4A6;
        font-size: 22px;
        font-weight: lighter;
        letter-spacing: 2.2px;
        text-align: center;
        margin-bottom: 16px;
    }

    span{
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
    }

    .icons{
        width: 600px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 50px;
    }

    .icon{
        width: 42px;
        height: 42px;
        margin-bottom: 20px;
    }

    .icon-container{
        margin-top: 76px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    small{
        color: white;
        font-size: 12px;
        font-weight: lighter;
    }

    .slogan{
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        letter-spacing: 5px;
        text-shadow: 1px 1px 19px #fce4a6;
        color: #E8E3D7;
        margin: 120px 0px;
    }

`