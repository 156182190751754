import * as S from './style'

function GetPDF(){

    return(
        <S.Style>
            <div className="container">
                <span className="slogan">We mystery shopped
                <strong> 126</strong> competidors for:</span>
                <div className="content">
                    <div>
                        <div className="square-text">
                            <div className="square"/>
                            <span>Pay</span>
                        </div>
                        <div className="square-text">
                            <div className="square"/>
                            <span>Qualifications</span>
                        </div>
                        <div className="square-text">
                            <div className="square"/>
                            <span>Availability</span>
                        </div>
                    </div>
                    <div className="button-container">
                        <button>Get PDF</button>
                        <span><small>(email required)</small></span>
                    </div>
                </div>
            </div>
        </S.Style>
    )
}

export default GetPDF