import styled from "styled-components"
import * as Scroll from 'react-scroll'
import arrow from '../../../../assets/arrowdown.png'

const scroll = Scroll.scroller

const Content = styled.div`

  position: relative;
  width: 100%;
  text-align: right;
  color: #fce4a6;
  cursor: pointer;

  img{
    width: 23px;
    height: 12px;
  }

  h1, h3{
      font-family: 'Roboto', sans-serif;
      font-weight: lighter;
      letter-spacing: 5px;
      text-shadow: 1px 1px 19px #fce4a6;
  }
    
  h3{
      color: white;
  }

`

function Title (){
    return(
        <Content onClick={()=>{
          scroll.scrollTo('refToArea01', {
          duration: 500,
          delay: 50,
          smooth: true,
          offset: 0,
          })}}>
          <h1>
            Value of <strong>Gold</strong> is in the rarity <br/>
            So are Good <strong>Coders</strong>
          </h1>
          <h3>
            That states the problem
          </h3>
          <img src={arrow} onClick={()=>{
          scroll.scrollTo('refToArea01', {
          duration: 500,
          delay: 50,
          smooth: true,
          offset: 0,
          })}}/>
        </Content>
    )
}

export default Title