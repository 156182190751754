import * as S from './style'
import clock from '../../../../assets/clock.png'
import coins from '../../../../assets/coins.png'

function CardsLarge(){
    return(
        <S.Style>
            <div className="container">
                <div className="frame">
                    <div className="column-side">
                        <div><span style={{position: 'relative', top: '30px'}}><strong>1</strong> hour risk</span></div>
                        <div><span>Intro of 30 minutes</span></div>
                    </div>
                    <div className="clock-container"><img id="clock" src={clock}/></div>
                    <div className="column-side"><span>30 minutes of programmer eval</span></div>
                </div>
                <div className="frame">
                <div className="column-side">
                        <div><span style={{position: 'relative', top: '30px'}}><strong>3</strong> out of <strong>4</strong></span></div>
                        <div><span>evaluated programmers are accepted onto teams</span></div>
                    </div>
                    <div className="icons-container">
                        <img id="icon" src={coins}/>
                    </div>
                </div>
            </div>
        </S.Style>
    )
}

export default CardsLarge