import React from 'react'
import ReactDOM from 'react-dom'
import './root.css'
import HomePage from './views/HomePage'

ReactDOM.render(
  <React.StrictMode>
    <HomePage/>
  </React.StrictMode>,
  document.getElementById('root')
);