import * as S from './style'
import * as Scroll from 'react-scroll'

function ImageAnimation() {
    
  const scroll = Scroll.scroller

  return (
    <>
      <S.Frame0/>
      <S.Frame1/>
      <S.Frame2/>

      <S.Areas>

        <a id="area01"
          /*
          onMouseOver={()=>{
            scroll.scrollTo('refToArea01', {
              duration: 500,
              delay: 50,
              smooth: true,
              offset: 50,
            })}}
            */
            />
            <div className="blowupanim01">
              <div className="speech-ballon">
                "Challenges and Aspirations"
              </div>
            </div>

        <a id="area02"/>
            <div className="blowupanim02">
              <div className="speech-ballon">
                "we are devs"
              </div>
            </div>

        <a id="area03"/>
            <div className="blowupanim03">
              <div className="speech-ballon">
                "hire us"
              </div>
            </div>
        
        <a id="area04"/>
            <div className="blowupanim04">
              <div className="speech-ballon">
                "this is a message"
              </div>
            </div>
        
        <a id="area05"/>
            <div className="blowupanim05">
              <div className="speech-ballon">
                "test text test text"
              </div>
            </div>
        
        <a id="area06"/>
            <div className="blowupanim06">
              <div className="speech-ballon">
                "test text test text"
              </div>
            </div>
        
        <a id="area07"/>
            <div className="blowupanim07">
              <div className="speech-ballon">
                "test text test text"
              </div>
            </div>
        
        <a id="area08"/>
            <div className="blowupanim08">
              <div className="speech-ballon">
                "test text test text"
              </div>
            </div>

      </S.Areas>
    </>
  );
}

export default ImageAnimation
