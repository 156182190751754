import styled from "styled-components"

export const Background = styled.div`

    z-index: 10;
    position: fixed;
    height: 220px;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    
    background: linear-gradient(
        180deg,
        #0F0F0F 0%,
        rgba(16,16,16,0.67)
        47%,
        rgba(17,17,17,0)
        100%);
    text-align: center;
    color: white;

`

export const Container = styled.div`

    width: 1300px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    margin-top: 35px;
    
    nav{
        display: flex;
        align-items: center;
        color: #E8E3D7;
        font-size: 14px;
        letter-spacing: 1.4px;
    }
    
    img.logo{
        width: 220px;
        height: fit-content;
        filter: drop-shadow(0px 0px 2px rgba(252,228,166,.7));
    }
    
    button{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        height: 50px;
        width: 241px;
        font-size: 18px;
        font-weight: lighter;
        letter-spacing: 1.8px;
        border-radius: 26px;
        background-color: #090909;
        border: 1px solid #FCE4A6;
        color: #E8E3D7;
        transition: .2s;
        cursor: pointer;
    }
    
    button:hover{
        background-color: #161515;
    }
    
    .menu-button{
        position: relative;
        top: 12px;
        height: 50px;
        padding: 0px 20px;
    }

    .menu-button::after{
        content: '';
        display: block;
        height: 1px;
        width: 0%;
        position: relative;
        top: 10px;
        background-color: #FCE4A6;
        transition: .15s;
    }

    .menu-button:hover::after{
        width: 100%;
    }

`