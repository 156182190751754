import * as S from './style'
import iconSeparator from '../../../../assets/separator.png'

function CardInfo(){

    return(
        <S.Style>
            <div className="container">
               <div className="box">
                   <div className="text-container">
                        <span>To safeguard our competitive advantage, we made
                        a conscious decision not to disclose too much.</span>
                   </div>
                   <img src={iconSeparator}/>
                   <div className="text-container">
                   <span>Nevertheless, we'll be happy to put you in
                        contact with an available programmer.</span>
                   </div>
               </div>
            </div>
        </S.Style>
    )
}


export default CardInfo