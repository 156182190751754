import * as S from './style'
import logo from '../../assets/GoldCode.png'

function Footer ({is_home_page}){

    return(
        is_home_page ?
        <S.Style>
            <S.StyleChild>
                <FooterContent/>
            </S.StyleChild>
        </S.Style>
        :
        <S.StyleChild>
            <FooterContent/>
        </S.StyleChild>
    )
}

function FooterContent(){
    return(
        <div className="container">
            <nav>
                <a href="#">Termas of Service</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Cookies Policy</a>
            </nav>
            <div className="line"/>
            <div className="below-line">
                <img className="logo" src={logo}/>
                <span>Software Application | Development Services</span>
                <span><small>© 2021 All Rights Reserved</small></span>
            </div>
        </div>
    )
}


export default Footer