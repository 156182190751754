import * as S from './style'
import iconSeniors from '../../../../assets/iconSeniors.png'
import iconTrusted from '../../../../assets/iconTrusted.png'
import iconBackground from '../../../../assets/iconBackground.png'
import iconTested from '../../../../assets/iconTested.png'
import iconVetted from '../../../../assets/iconVetted.png'
import iconTracking from '../../../../assets/iconTracking.png'
import iconAutomated from '../../../../assets/iconAutomated.png'

function WeEvolving(){
    return(
        <S.Style>
            <div className="container">
                <span className="title"> We've been evolving </span>
                <span className="text-center">
                    Our management choices have come withcountless mistakes,<br/>
                    all to lead us to micro-management where it counts,
                    hiring.
                </span>
                <div className="cards-panel">
                    <Card
                        icon = {iconSeniors}
                        title = "Seniors"
                        text = {`We hire seniors who don't make mistakes
                                that add up to huge technical debt.`}
                    />
                    <Card
                        icon = {iconTrusted}
                        title = "Trusted"
                        text = {`Some of the Fortune 50 trust us
                                to architect software they use.`}
                    />
                    <Card
                        icon = {iconBackground}
                        title = "Background"
                        text = {`We actually do criminal, checks, social, reference,
                                and general internet checks.`}
                    />
                    <Card
                        icon = {iconTested}
                        title = "Tested"
                        text = {`We do a take-home test, live-coding test,
                                and several tasks before hiring.`}
                    />
                    <Card
                        icon = {iconVetted}
                        title = "Vetted"
                        text = {`New hires are vetted for at least
                                three months on internal projects.`}
                    />
                    <Card
                        icon = {iconTracking}
                        title = "Micro-tracking"
                        text = {`We track screenshots, activity,
                                time per issue, to evaluate ROI.`}
                    />
                    <Card
                        icon = {iconAutomated}
                        title = "Automated"
                        text = {`Our automated software sends hundreds of
                                signals for insights making faster senior managers.`}
                    />
                </div>
            </div>
        </S.Style>
    )

}

function Card({ icon, title, text}) {
    return(
        <div className="card">
            <img src={icon}/>
            <span className="card-title">{title}</span>
            <span className="card-text">{text}</span>
        </div>
    )
}

export default WeEvolving