import * as S from './style'
import icon1 from '../../../../assets/icon1.png'
import icon2 from '../../../../assets/icon2.png'
import icon3 from '../../../../assets/icon3.png'

function TaskLevel(){

    return(
        <S.Style>
            <div className="container">
                <InfoIcons
                    icon = {icon1}
                    title = {'80 hours at 50%'}
                    text = {`Test our competativeness on
                            customer facing business value.`}
                    size = {{height: '81.32px', width: '80.09px'}}
                />
                <InfoIcons
                    icon = {icon2}
                    title = {'Task-level management'}
                    text = {`We connect your project
                        management software to measure
                        make small quick wins that big enought to learn.`}
                    size = {{height: '78.32px', width: '92.79px'}}
                    
                    />
                <InfoIcons
                    icon = {icon3}
                    title = {'Daily reports'}
                    text = {`Clients have online access to real-time
                    updates made through our tracking system.`}
                    size = {{height: '79.03px', width: '83.61px'}}
                />
            </div>
        </S.Style>
    )
}

function InfoIcons({icon, title, text, size}){

    return(
        <S.IconsContainer>
            <img src={icon} style={size}/>
            <div className="title"><span>{title}</span><br/></div>
            <span>{text}</span>
        </S.IconsContainer>
    )

}

export default TaskLevel