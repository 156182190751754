import styled from "styled-components"

export const Style = styled.div`

    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
  
    .frame{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;
        height: 252.14px;
        width: 596px;
        border: 1px solid #FCE4A6;
        border-radius: 20px;
        background: linear-gradient(270deg, #141413 0%, #1D1E1B 52%, #141413 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        margin: 30px 0px;
    }

    #clock {
        height: 174px;
        width: 129px;
    }

    .clock-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #icon {
        width: 170px;
        height: 183px;
    }

    .icons-container{
        position: relative;
        left: -20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    span{
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        font-size: 13px;
        letter-spacing: 5px;
        color: #E8E3D7;
        margin: 120px 0px;
    }

    .column-side{
        width: 100%;
        display: grid;
        justify-content: left;
        text-align: left;
        margin: 0px 35px;
    }

`