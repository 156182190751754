import * as S from './style'

function SeeOurPricing(){
    return(
        <S.Style>
            <div className="container">
                <button>See Our Pricing</button>
                <span><small>(company email verification required)</small></span>
            </div>
        </S.Style>
    )
}

export default SeeOurPricing