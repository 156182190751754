import * as S from './style'
import diagram from '../../../../assets/diagram.png'

function Hiring() {
    return(
        <S.Style>
            <div className="container">
                <span className="title">Hiring</span>
                <span>We excel at the basics</span>
                <img src={diagram}/>
            </div>
        </S.Style>
    )
}

export default Hiring