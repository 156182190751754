import styled from "styled-components"

export const Style = styled.div`

    margin-top: 120px;
    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .slogan{
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        font-size: 20px;
        letter-spacing: 5px;
        text-shadow: 1px 1px 19px #fce4a6;
        color: #E8E3D7;
        margin: 120px 0px 62px 0px;
    }

    span{
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
    }

    .square{
        position: relative;
        top: 4px;
        height: 15px;
        width: 15px;
        background-color: #FCE4A6;
        margin-right: 25px;
    }

    .square-text{
        margin: 20px 0px;
        display: flex;
        flex-direction: row;
    }

    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-end;
        width: 80%;
    }

    button {
        box-sizing: border-box;
        height: 61.3px;
        width: 280px;
        border-radius: 26px;
        background-color: #090909;
        border: 1px solid #FCE4A6;

        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: lighter;
        color: #E8E3D7;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 18px;
        text-align: center;
        transition: .2s;
        cursor: pointer;
    }
    
    button:hover{
        background-color: #161515;
    }

    .button-container{
        position: relative;
        top: -20px;
        display: flex;
        flex-direction: column;
    }
`