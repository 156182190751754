import styled from "styled-components"


export const Style = styled.div`

    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }
    
    .title{
        color: #FCE4A6;
        font-size: 22px;
        font-weight: lighter;
        letter-spacing: 2.2px;
        text-align: center;
        margin-bottom: 16px;
    }

    span{
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
    }

    img{
        width: 654px;
        height: 610px;
        margin: 65px 0px 90px 0px;
    }

`