import * as S from './style.js'

function ChallengesAspirations() {
    return (
        <S.Container>
            
            <div className="row">
                <div className="text-box right">
                    <span className="title">
                        Challenges
                        </span>
                </div>

                <div className="center-column">
                    <span className="title">&</span>
                </div>
                
                <div className="text-box">
                    <span className="title">
                        Aspirations
                    </span>
                </div>
            </div>

            <div className="row">

                <div className="column">
                    <div className="text-box right">
                        <span>
                            Senior developer<br/>oversight
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Developers<br/>getting poached
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Burn out or<br/>lack of motivation
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Inneficiency effect<br/>of groups
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Missunderstanding<br/>requirements
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Over-engineering<br/>and technical debt
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Aversion to<br/>challenges and leadership
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Delayed drifting<br/>deadlines
                        </span>
                    </div>
                    <div className="text-box right">
                        <span>
                            Frequently<br/>rejected PRs
                        </span>
                    </div>
                </div>

                <div className="center-column">
                    <div id="center-line"></div>
                </div>
                
                <div className="column">
                    <div className="text-box">
                        <span>
                            Engineering Management<br/>and training
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Passionate culturing<br/>of mentoring
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Reliable appreciation<br/>of effort
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Single threaded<br/>focus
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Break problems<br/>down
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Faster ownership<br/>and autonomy
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Tight team<br/>cohesion
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Cutting features to<br/>ship frequently
                        </span>
                    </div>
                    <div className="text-box">
                        <span>
                            Dev QA tooling<br/>Test-driven development
                        </span>
                    </div>
                </div>
            </div>


        </S.Container>
    )
}

export default ChallengesAspirations