import styled, { keyframes } from 'styled-components'
import frame0 from '../../../../assets/illustration-frame0.png'
import frame1 from '../../../../assets/illustration-frame1.png'
import frame2 from '../../../../assets/illustration-frame2.png'
import anim01 from '../../../../assets/anim01.gif'
import anim02 from '../../../../assets/anim02.gif'
import anim03 from '../../../../assets/anim03.gif'
import anim04 from '../../../../assets/anim04.gif'
import anim05 from '../../../../assets/anim05.gif'
import anim06 from '../../../../assets/anim06.gif'
import anim07 from '../../../../assets/anim07.gif'
import anim08 from '../../../../assets/anim08.gif'

const imageWidth = '682px'
const imageHeight = '819px'
const leftOffset = '-80px'
const animationSpeed = '.7s'

const Anim1 = keyframes`

  0%   { opacity: 0; }
  30%  { opacity: 1; }
  60%  { opacity: 0; }
  100% { opacity: 0; }

`

const Anim2 = keyframes`

  0%   { opacity: 0; }
  30%  { opacity: 0; }
  60%  { opacity: 1; }
  100% { opacity: 0; }

`

export const Frame0 = styled.div`

  position: fixed;
  bottom: 0px;
  z-index: -10;
  transform: translate(${leftOffset});
  width: ${imageWidth};
  height: ${imageHeight};
  background-image: url(${frame0});

`

export const Frame1 = styled.div`

  position: fixed;
  bottom: 0px;
  z-index: -9;
  transform: translate(${leftOffset});
  width: ${imageWidth};
  height: ${imageHeight};
  background-image: url(${frame1});
  animation: ${Anim1}
    ${animationSpeed} ease 0s infinite normal;

`

export const Frame2 = styled.div`

  position: fixed;
  bottom: 0px;
  z-index: -8;
  transform: translate(${leftOffset});
  width: ${imageWidth};
  height: ${imageHeight};
  background-image: url(${frame2});
  animation: ${Anim2}
    ${animationSpeed} ease 0s infinite normal;
    
`

const CURSORTYPE = 'crosshair'
const positionArea01 = '64px, -570px'
const sizeArea01 = [ 164, 134 ]
const positionArea02 = '239px, -568px'
const sizeArea02 = [ 214, 145 ]
const positionArea03 = '116px, -453px'
const sizeArea03 = [ 300, 86 ]
const positionArea04 = '138px, -384px'
const sizeArea04 = [ 267, 64 ]
const positionArea05 = '117px, -251px'
const sizeArea05 = [ 85, 130 ]
const positionArea06 = '218px, -275px'
const sizeArea06 = [ 192, 91 ]
const positionArea07 = '224px, -180px'
const sizeArea07 = [ 194, 80 ]
const positionArea08 = '87px, -45px'
const sizeArea08 = [ 357, 136 ]
const animOffset = 20
const animPositionX = 520
const animPositionY = -218
export const Areas = styled.div`


 #area01{
    width: ${sizeArea01[0]}px;
    height: ${sizeArea01[1]}px;
    transform: translate( ${positionArea01} );

    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area02{
    width: ${sizeArea02[0]}px;
    height: ${sizeArea02[1]}px;
    transform: translate( ${positionArea02} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area03{
    width: ${sizeArea03[0]}px;
    height: ${sizeArea03[1]}px;
    transform: translate( ${positionArea03} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area04{
    width: ${sizeArea04[0]}px;
    height: ${sizeArea04[1]}px;
    transform: translate( ${positionArea04} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area05{
    width: ${sizeArea05[0]}px;
    height: ${sizeArea05[1]}px;
    transform: translate( ${positionArea05} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area06{   
    width: ${sizeArea06[0]}px;
    height: ${sizeArea06[1]}px;
    transform: translate( ${positionArea06} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area07{
    width: ${sizeArea07[0]}px;
    height: ${sizeArea07[1]}px;
    transform: translate( ${positionArea07} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }
  
  #area08{
    width: ${sizeArea08[0]}px;
    height: ${sizeArea08[1]}px;
    transform: translate( ${positionArea08} );
    
    position: fixed;
    bottom: 0px;
    z-index: 20;
    cursor: ${CURSORTYPE};
  }

  .speech-ballon{
    position: relative;
    transform: translate(0px,-60px);
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #e8e3d7;
    font-weight: bold;
    color: black;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  }
  
  .blowupanim01{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim01} );

    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area01:hover + .blowupanim01{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim02{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim02} );
    
    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;
    
    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area02:hover + .blowupanim02{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim03{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim03} );
    
    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area03:hover + .blowupanim03{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim04{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim04} );
    
    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area04:hover + .blowupanim04{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim05{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim05} );

    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area05:hover + .blowupanim05{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim06{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim06} );

    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area06:hover + .blowupanim06{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim07{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim07} );

    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area07:hover + .blowupanim07{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }
  
  .blowupanim08{
    z-index: -90;
    transform: translate( ${animPositionX}px, ${animPositionY - animOffset}px );
    width: 640px;
    height: 425px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    background-image: url( ${anim08} );

    opacity: 0;
    position: fixed;
    bottom: 0px;
    padding: 10px 20px;
    border-radius: 26px;
    background-color: #090909;
    border: 1px solid #FCE4A6;
    color: white;
    transition: .2s;

    box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -webkit-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
    -moz-box-shadow: 0 0 19px rgba(0, 0, 0, 0.9)  ; 
  } #area08:hover + .blowupanim08{
    z-index: 90;
    transform: translate(  ${animPositionX}px, ${animPositionY}px );
    opacity: 1;
  }

`