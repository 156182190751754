import styled from 'styled-components'

export const Style = styled.div`

    margin-top: 105px;
    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    button {
        box-sizing: border-box;
        height: 61.3px;
        width: 363.13px;
        border-radius: 26px;
        background-color: #090909;
        border: 1px solid #FCE4A6;

        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: lighter;
        color: #E8E3D7;
        font-size: 18px;
        letter-spacing: 1.8px;
        line-height: 18px;
        text-align: center;
        transition: .2s;
        cursor: pointer;
    }

    button:hover{
        background-color: #161515;
    }

    span{
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
        font-weight: lighter;
    }

`