import React from 'react'
import * as S from './style'
import logo from '../../assets/GoldCode.png'
import * as Scroll from 'react-scroll'


function Header() {

    const scroll = Scroll.scroller;

    return(
        <S.Background>
            <S.Container>
                <img className="logo" src={logo}
                    onMouseOver={()=>{
                        scroll.scrollTo('refToTop', {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -1000,
                        })}}/>
                <nav>
                    <a className="menu-button" href="#">Home</a>
                    <a className="menu-button" href="#">About Us</a>
                    <a className="menu-button" href="#">Services</a>
                    <a className="menu-button" href="#">Security</a>
                    <a className="menu-button" href="#">Pricing</a>
                </nav>
                <button>Customer Login</button>
            </S.Container>
        </S.Background>    
    )
}

export default Header