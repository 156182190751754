import styled from "styled-components"

export const Style = styled.div`

    margin-top: 180px;
    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

`

export const IconsContainer = styled.div`

    color: #E8E3D7;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 2.57px;
    line-height: 27px;
    font-weight: lighter;
    text-align: center;

    width: 200px;
    height: 100%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    img{
        margin-bottom: 12px;
    }

    .title{
        display: flex;
        align-items: center;
        height: 80px;
        font-size: 14px;
        letter-spacing: 3px;
        font-weight: bold;
    }

`