import * as S from './style'
import iconFacebook from '../../../../assets/ico_fb.png'
import iconTelegram from '../../../../assets/ico_telegram.png'
import iconSlack from '../../../../assets/ico_slack.png'
import iconLinkedIn from '../../../../assets/ico_linkedin.png'
import iconWhatsApp from '../../../../assets/ico_whatsapp.png'
import iconViber from '../../../../assets/ico_viber.png'
import iconGitHub from '../../../../assets/ico_github.png'
import iconStack from '../../../../assets/ico_stack.png'
import iconConferences from '../../../../assets/ico_conf.png'
import iconMeetups from '../../../../assets/ico_meetup.png'

function Partners() {
    return(
        <S.Style>
            <div className="container">
                <span className="title">Partners in Gold Mining</span>
                <span>We're everywhere</span>
                <div className="icons">
                    <Icon icon={iconFacebook} name="Facebook groups"/>
                    <Icon icon={iconTelegram} name="Telegram groups"/>
                    <Icon icon={iconSlack} name="Slack channels"/>
                    <Icon icon={iconLinkedIn} name="Linkedin"/>
                    <Icon icon={iconWhatsApp} name="WhatsApp groups"/>
                    <Icon icon={iconViber} name="Viber"/>
                    <Icon icon={iconGitHub} name="GitHub"/>
                    <Icon icon={iconStack} name="Stack Overflow"/>
                    <Icon icon={iconConferences} name="Conferences"/>
                    <Icon icon={iconMeetups} name="Meetups events"/>
                </div>
                <span>We also use rumor mills as referral radiators.</span>
                <div className="slogan">Reach over <strong>250</strong> cities <strong>+1000</strong> locations<br/>
                    with our custom omni-channel solution.
                </div>
            </div>
        </S.Style>
    )
}

function Icon( { icon, name } ){
    return(
        <div className="icon-container">
            <img className="icon" src={icon}/>
            <small>{name}</small>
        </div>
    )
}

export default Partners