import styled from 'styled-components'

export const Style = styled.div`

    display: flex;
    justify-content: flex-end;

    .container{
        width: 640px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
`

export const StyleChild = styled.div`

    margin-top: 120px;

    a{
        font-size: 10px;
        letter-spacing: 2.14px;
        font-weight: lighter;
        margin-right: 33px;
    }
    
    small{
        font-size: 8px;
        letter-spacing: 1.71px;
        font-weight: lighter;
    }
    
    .line{
        width: 100%;
        height: 1px;
        background-color: #FCE4A6;
        margin: 12px 0px;
    }
    
    img.logo{
        width: 136px;
        height: fit-content;
        filter: drop-shadow(0px 0px 2px rgba(252,228,166,.7));
    }
    
    .below-line{
        font-size: 10px;
        letter-spacing: 2.14px;
        font-weight: lighter;
        color: #E8E3D7;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

`