import * as S from './style'
import ImageAnimation from './sections/ImageAnimation'
import Title from './sections/Title'
import WeEvolving from './sections/WeEvolving'
import Header from '../../components/Header'
import ChallengesAspirations from './sections/ChallengesAspirations'
import Partners from './sections/Partners'
import Hiring from './sections/Hiring'
import CardsLarge from './sections/CardsLarge'
import SeeOurPricing from './sections/SeeOurPricing'
import TaskLevel from './sections/TaskLevel'
import CardInfo from './sections/CardInfo'
import GetPDF from './sections/GetPDF'
import Footer from '../../components/Footer'
import { Element } from 'react-scroll'

function HomePage() {
    return (
     <>
        {/*anchor from animation*/}
        <Element name="refToTop"/>
        
        <Header/>
          <S.MarginSet>

              <ImageAnimation />
              <Title />

              {/*anchor from animation*/}
              <Element name="refToArea01"/>
              <ChallengesAspirations/>
              {/**/}

              {/*anchor from animation*/}
              <Element name="refToArea02"/>
              <WeEvolving/>
              {/**/}

              {/*anchor from animation*/}
              <Element name="refToArea03"/>
              <Partners/>
              {/**/}

              <Hiring/>
              <CardsLarge/>

              {/*anchor from animation*/}
              <Element name="refToArea04"/>
              <SeeOurPricing/>
              {/**/}

              <TaskLevel/>
              <CardInfo/>
              <GetPDF/>

              {/*anchor from animation*/}
              <Element name="refToArea05"/>
              <Footer is_home_page = {true}/>
              {/**/}

              
          </S.MarginSet>
        <S.ComponentGradient/>
      </>
    );
  }
  
  export default HomePage