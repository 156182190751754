import styled from "styled-components"

export const Style = styled.div`

    margin-top: 120px;
    display: flex;
    justify-content: flex-end;

    .container{
        width: 690px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .text-container{
        display: flex;
        width: 220px;
        margin: 20px;
    }

    .box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        height: 219px;
        width: 596px;
        border: 1px solid #FCE4A6;
        border-radius: 20px;
        background: linear-gradient(270deg, #141413 0%, #1D1E1B 52%, #141413 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    }

    img{
        width: 50px;
        height: 28px;
    }

    span{
        color: #E8E3D7;
        font-family:
            -apple-system, BlinkMacSystemFont,
            Roboto, Oxygen, Ubuntu, Cantarell,
            'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
        font-weight: lighter;
    }
`