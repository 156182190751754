import styled from "styled-components"


export const Style = styled.div`
    
    margin-top: 105px;
    display: flex;
    justify-content: flex-end;

    .title{
        color: #FCE4A6;
        font-size: 22px;
        font-weight: lighter;
        letter-spacing: 2.2px;
        text-align: center;
        margin-bottom: 45px;
    }

    span{
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
    }

    .container{
        width: 690px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .cards-panel{
        display: flex;
        padding-top: 50px;
        justify-content: center;
        flex-wrap: wrap;
    }

    .card{
        margin: 20px;
        height: 310px;
        width: 217px;
        border: 1px solid #FCE4A6;
        border-radius: 20px;
        background: linear-gradient(270deg, #141413 0%, #1D1E1B 52%, #141413 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .card img{
        height: 90px;
        margin: 30px;
    }

    .card-title{
        color: #E8E3D7;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .card-text{
        color: #E8E3D7;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 12px;
        letter-spacing: 1.2px;
        line-height: 24px;
        text-align: center;
        margin: 0px 12px;
    }
`