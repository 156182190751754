import styled from 'styled-components'

export const MarginSet = styled.div`
    
    margin: auto;
    max-width: 1200px;
    padding: 50px;
    margin-top: 65vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    
`

export const ComponentGradient = styled.div`

  z-index: -99;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background-image: linear-gradient(0deg, rgba(72,69,66,1) 0%, rgba(46,43,40,1) 20%, rgba(46,43,40,0) 60%);
  background-position: bottom;
  background-repeat: repeat-x;

`