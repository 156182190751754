import styled from "styled-components"

export const Container = styled.div`

    margin-top: 190px;
    margin-bottom: 105px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    
    .text-box{
        display: flex;
        align-items: center;
        width: 280px;
        height: 110px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .right{
        justify-content: flex-end;
        text-align: right;
    }

    .title{
        color: #FCE4A6;
        font-size: 22px;
        font-weight: lighter;
        letter-spacing: 2.2px;
        text-align: center;
        text-transform: uppercase;
    }

    .center-column{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 130px;
    }

    span{
        color: #E8E3D7;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 27px;
        font-family: 'Roboto', sans-serif;
    }

    .row{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .column{
        display: flex;
        flex-direction: column;
    }

    #center-line{
        width: 1px;
        height: 100%;
        background-color: #FCE4A6;
    }
`
